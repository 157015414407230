.ba__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ba__main > h2 {
  font-size:calc(25px + 15vw);
  line-height: 12.8vw;
  margin: 0;
  padding: 0;
  font-family: 'Righteous', cursive;
  text-align: center;
}

.ba__main > span, label {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
}

.ba__main > span {
  font-size: calc(12px + 1.2vw);
}

.ba__main

html, 
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}