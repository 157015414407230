.ba__main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ba__main > h2 {
  font-size:calc(25px + 15vw);
  line-height: 12.8vw;
  margin: 0;
  padding: 0;
  font-family: 'Righteous', cursive;
  text-align: center;
}

.ba__main > span, label {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
}

.ba__main > span {
  font-size: calc(12px + 1.2vw);
}

.ba__main

html, 
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

.ba__frontpage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  height: 100%;
  background-size: 4525px 100%;
  background-image: -webkit-repeating-linear-gradient(135deg, #E08E79, #E08E79 200px, #F1D4AF 200px, #F1D4AF 400px);
  background-image: repeating-linear-gradient(-45deg, #E08E79, #E08E79 200px, #F1D4AF 200px, #F1D4AF 400px);
  -webkit-animation: 15s progslide infinite linear;
          animation: 15s progslide infinite linear;
  -webkit-transition: 2s ease;
  transition: 2s ease; }
  .ba__frontpage > h2 {
    font-size: calc(25px + 15vw);
    line-height: 12.8vw;
    margin: 0;
    padding: 0;
    font-family: 'Righteous', cursive;
    text-align: center; }
  .ba__frontpage > label {
    font-size: calc(10px + 0.5vw); }
  .ba__frontpage > span {
    font-size: calc(12px + 1.2vw); }
    .ba__frontpage > span, .ba__frontpage > span label {
      font-family: 'Quicksand', sans-serif;
      text-align: center; }

@-webkit-keyframes progslide {
  0% {
    background-position-x: 0px; }
  100% {
    background-position-x: 4525px; } }

@keyframes progslide {
  0% {
    background-position-x: 0px; }
  100% {
    background-position-x: 4525px; } }

